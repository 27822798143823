







































import Vue from "vue";

export default Vue.extend({
  name: "ScreeningSelectBox",
  props: ["text", "id"],
  methods: {
    changeAnswerValue(score: number) {
      this.$store.commit("dementia/changeScreeningAnswerValue", {
        id: Number(this.$props.id),
        score: score
      });
    }
  },
  computed: {
    checkedScore() {
      return this.$store.state.dementia.screeningAnswers[
        Number(this.$props.id)
      ];
    }
  }
});
