





































import ScreeningSelectBox from "@/components/dementia/ScreeningSelectBox.vue";
import rootStore from "@/store";
import { DementiaState } from "@/types/dementia";
const state = rootStore.state as { dementia: DementiaState };
export default {
  name: "Screening",
  components: { ScreeningSelectBox },
  head: {
    title: {
      inner: "認知症かんたんチェック"
    }
  },
  data: function() {
    return {
      questions: [
        "同じ話を無意識に繰り返す",
        "知っている人の名前が思い出せない",
        "物のしまい場所を忘れる",
        "漢字を忘れる",
        "今しようとしていることを忘れる",
        "器具の説明書を読むのを面倒がる",
        "理由もないのに気がふさぐ",
        "身だしなみに無関心である",
        "外出をおっくうがる",
        "物（財布など）が見当たらないことを他人のせいにする"
      ]
    };
  },
  computed: {
    totalScore(): number {
      const answers = state.dementia.screeningAnswers.filter(
        (el): el is number => typeof el == "number"
      );
      return answers.reduce((acc, val) => {
        return acc + val;
      }, 0);
    },
    isAnswerComplete(): boolean {
      return !state.dementia.screeningAnswers.includes(undefined);
    }
  },
  methods: {
    commitScore(score: number): void {
      rootStore.commit("dementia/changeTotalScoreValue", {
        value: score
      });
    }
  }
};
