var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white-box"},[_c('div',{staticClass:"button-block"},[_c('p',{staticClass:"question"},[_vm._v(_vm._s(_vm.text))]),_c('p',{staticClass:"button-label planbutton",class:{
        activebutton: _vm.checkedScore == 0,
        inactivebutton: _vm.checkedScore != 0 && _vm.checkedScore != undefined
      },on:{"click":function($event){return _vm.changeAnswerValue(0)}}},[_vm._v(" ほとんどない ")]),_c('p',{staticClass:"button-label planbutton",class:{
        activebutton: _vm.checkedScore == 1,
        inactivebutton: _vm.checkedScore != 1 && _vm.checkedScore != undefined
      },on:{"click":function($event){return _vm.changeAnswerValue(1)}}},[_vm._v(" 時々ある ")]),_c('p',{staticClass:"button-label planbutton",class:{
        activebutton: _vm.checkedScore == 2,
        inactivebutton: _vm.checkedScore != 2 && _vm.checkedScore != undefined
      },on:{"click":function($event){return _vm.changeAnswerValue(2)}}},[_vm._v(" 頻繁にある ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }